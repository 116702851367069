import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Box, Button, Typography, CircularProgress, Container, Card, CardContent, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Header, Footer, ErrorPage, LoadingPage } from 'swartbox-theme';
import { auth } from 'swartbox-auth-library'; 
import Cookies from 'js-cookie';


const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Voer een geldig e-mailadres in')
    .required('E-mailadres is verplicht'),
  authCode: yup
    .string()
    .when('userExists', {
      is: true,
      then: (schema) => schema
        .required('Authenticatiecode is verplicht')
        .length(6, 'De authenticatiecode moet precies 6 tekens lang zijn'),
      otherwise: (schema) => schema.nullable(),
    }),
  userExists: yup.boolean().default(false),
});

const Login = () => {
  const [loading, setLoading] = useState(true);  // Toont laadstatus
  const [error, setError] = useState(null);  // Voor foutafhandeling
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(''); // State voor foutmeldingen
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);  // Start met 60 seconden
  const hasMounted = useRef(false);

  const { handleSubmit, control, formState: { errors, isSubmitting, isValid }, setValue, watch } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      email: '',
      authCode: '',
      userExists: false,
    }
  });

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      const verifyLogin = async () => {
        try {
          const queryParams = new URLSearchParams(window.location.search); 
          const redirectURL = queryParams.get('redirect_url');
    
          if (!redirectURL) {
            setError('No redirect URL found.');
            return;
          }
    
          const { isLoggedIn, isAuthorized } = await auth.checkLoginStatus();    
          if (isLoggedIn) {
            // Als ingelogd, stuur door naar de redirect URL
            window.location.href = redirectURL;
          } else {
            // Gebruiker is niet ingelogd, controleer of er een authToken bestaat
            if (isAuthorized) {
              // Als er een authToken is, laat het inlogscherm met code zien
              setValue('userExists', true);
            }
          }
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false); 
        }
      };
    
      verifyLogin();  
    }
  }, [setValue]); 

  useEffect(() => {
    let timer;
    if (resendDisabled) {
      timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            setResendDisabled(false);  // Schakel de knop weer in
            return 60;  // Reset de timer naar 60 seconden
          }
          return prev - 1;  // Verminder de tijd met 1 seconde
        });
      }, 1000);  // Update elke seconde
    }
    return () => clearInterval(timer);  // Opruimen van de timer bij het unmounten
  }, [resendDisabled]);

  if (loading) return <LoadingPage />;

  if (error) return <ErrorPage message={error} />;

  const handleResendCode = async (email) => {
    setResendDisabled(true);  // Schakel de knop uit
    await auth.generateAuthCode(email);  // Verstuur de code opnieuw
  };

  const userExists = watch('userExists');
  const email = watch('email'); // Houd het e-mailadres bij om het te tonen in de bevestigingsstap

  const onSubmit = async (data) => {
    if (data.userExists) {
      const result = await auth.verifyAuthCode(data.authCode);
      if (result.success) {
        const queryParams = new URLSearchParams(window.location.search);
        const redirectURL = queryParams.get('redirect_url') || '/user/profile';
        window.location.href = redirectURL;
      }else{
        setErrorMessage(result.message);
      }
    } else {
      const result = await auth.generateAuthCode(data.email);
      if (result.success) {
        setResendDisabled(true);
        setValue('userExists', true);
        setErrorMessage('');
      }else{
        setErrorMessage(result.message);
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Container sx={{ flex: 1, mt: 4 }} component="main" maxWidth="md">
        <Typography variant="h4" component="h1" sx={{ mb: 4, textAlign: 'center' }}>
          Log in
        </Typography>
        <Card sx={{ border: '1px solid #ddd', boxShadow: 1 }}>
          <CardContent>
            <Container maxWidth="xs">
              <Typography variant="h6" sx={{ mb: 2 }}>
                {userExists ? `Authenticatie voor ${email}` : 'Log in met je account'}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {userExists 
                  ? 'Voer hieronder de authenticatiecode in die naar je e-mailadres is gestuurd.'
                  : 'Begin hieronder met het invullen van je e-mailadres.'}
              </Typography>
              {errorMessage && (
                <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                  {errorMessage}
                </Typography>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                {!userExists && (
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="E-mail"
                        type="email"
                        fullWidth
                        sx={{ mb: 2 }}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ''}
                      />
                    )}
                  />
                )}
                {userExists && (
                  <Controller
                    name="authCode"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Authenticatiecode"
                        fullWidth
                        sx={{ mb: 2 }}
                        error={!!errors.authCode}
                        helperText={errors.authCode ? errors.authCode.message : ''}
                      />
                    )}
                  />
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isValid || isSubmitting}
                    startIcon={isSubmitting && <CircularProgress size={24} />}
                  >
                    {isSubmitting ? '' : userExists ? 'Verifieer Code' : 'Verder gaan'}
                  </Button>
                  {userExists && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleResendCode(watch().email)}
                      disabled={resendDisabled || isSubmitting}
                    >
                      {resendDisabled ? `Wacht ${resendTimer} sec` : 'Verzend Code Opnieuw'}
                    </Button>
                  )}
                </Box>
              </form>
            </Container>
          </CardContent>
        </Card>
        <Link href="#" onClick={() => navigate(-1)} sx={{ textAlign: 'left', display: 'block', mt: 2 }}>
          Naar vorige stap
        </Link>
      </Container>
      <Footer />
    </Box>
  );
};

export default Login;
