import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { theme } from 'swartbox-theme';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './pages/Login';
import Logout from './pages/Logout';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
            </Routes>
          </Router>
    </ThemeProvider>
  );
}

export default App;
