import React, { useEffect, useState, useRef } from 'react';
import { Header, Footer } from 'swartbox-theme';
import { CircularProgress, Container, Typography, Box, useTheme } from '@mui/material';
import { auth } from 'swartbox-auth-library'; 

const Logout = () => {
  const [error, setError] = useState(null);
  const theme = useTheme();  // Gebruik het huidige thema
  const hasMounted = useRef(false);

  useEffect(() => {
    // Check if the effect has already run
    if (!hasMounted.current) {
      hasMounted.current = true;  // Mark as mounted to avoid rerun

      const logoutUser = async () => {
        try {
          await auth.logout();
          const queryParams = new URLSearchParams(window.location.search);
          const redirectURL = queryParams.get('redirect_url') || 'https://www.swartbox.nl';
          window.location.href = redirectURL;
        } catch (error) {
          console.error('Error during logout:', error);
          setError('Er is iets misgegaan tijdens het uitloggen. Probeer het opnieuw.');
        }
      };

      logoutUser();  // Perform the logout action
    }
  }, []);  // Empty dependency array ensures the effect runs once

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Header />
      <Container
        maxWidth="sm"
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          padding={3}
          borderRadius={2}
          boxShadow={3}
          bgcolor={theme.palette.background.paper}
        >
          {error ? (
            <Typography
              variant="h6"
              sx={{ marginBottom: 2, color: theme.palette.error.main }}
            >
              {error}
            </Typography>
          ) : (
            <>
              <CircularProgress size={60} thickness={4} />
              <Typography
                variant="h6"
                sx={{ marginTop: 2, color: theme.palette.text.primary }}
              >
                Uitloggen...
              </Typography>
            </>
          )}
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Logout;
